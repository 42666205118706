import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import M from 'materialize-css';

import Can from '../../routes/Can';

export const MENU_DEFINITION = [
    {
        'id': 'StudentDropdown',
        'title': 'menu_alumno',
        'icon': 'people',
        'perform': 'AreaAlumnos:menu',
        'options': [
            {
                'title': 'menu_alumno_cursos',
                'action': '/area-alumno/cursos',
                'perform': 'AreaAlumno:AlumnosCursos:index'
            },
            {
                'title': 'menu_alumno_documentos',
                'action': '/area-alumno/documentos',
                'perform': 'AreaAlumno:Documentos:index'
            },
            {
                'title': 'menu_alumno_tareas',
                'action': '/area-alumno/tareas',
                'perform': 'AreaAlumno:TareasAlumnos:index'
            },
            {
                'title': 'menu_alumno_diplomas',
                'action': '/area-alumno/diplomas',
                'perform': 'AreaAlumno:AlumnosDiplomas:index'
            },
            {
                'title': 'menu_libros',
                'action': '/biblioteca/libros',
                'perform': 'BibliotecaLibros:index'
            }
        ]
    },
    {
        'id': 'StudentsDropdown',
        'title': 'menu_alumnos',
        'icon': 'people',
        'perform': 'Alumnos:menu',
        'options': [
            {
                'title': 'menu_alumnos',
                'action': '/alumnos',
                'perform': 'Alumnos:index'
            },
            {
                'title': 'menu_alumnos_load',
                'action': '/alumnos/cargar',
                'perform': 'Alumnos:load'
            },
            {
                'title': 'menu_titulaciones',
                'action': '/alumnos/buscador-titulaciones',
                'perform': 'AlumnosTitulaciones:index'
            }
        ]
    },
    {
        'id': 'CoursesDropdown',
        'title': 'menu_cursos',
        'icon': 'event',
        'perform': 'Cursos:menu',
        'options': [
            {
                'title': 'menu_cursos',
                'action': '/cursos',
                'perform': 'Cursos:index'
            },
            {
                'title': 'menu_inscripciones',
                'action': '/cursos/inscripciones',
                'perform': 'AlumnosCursos:index'
            },
            {
                'title': 'menu_organizadores',
                'action': '/organizadores',
                'perform': 'Organizadores:index'
            },
            {
                'title': 'menu_formadores',
                'action': '/formadores-homologados',
                'perform': 'FormadoresHomologados:index'
            },
            {
                'title': 'menu_carga_cursos_online',
                'action': '/cursos/online/carga',
                'perform': 'Cursos:cargarCursosOnline'
            }
        ]
    },
    {
        'id': 'DiplomasDropdown',
        'title': 'menu_diplomas',
        'icon': 'card_membership',
        'perform': 'Diplomas:menu',
        'options': [
            {
                'title': 'menu_diplomas',
                'action': '/diplomas',
                'perform': 'Diplomas:index'
            },
            {
                'title': 'menu_diplomas_alumnos',
                'action': '/diplomas/alumnos',
                'perform': 'AlumnosDiplomas:index'
            }
        ]
    },
    {
        'id': 'DocumentsDropdown',
        'title': 'menu_documentos',
        'icon': 'inbox',
        'perform': 'Documentos:menu',
        'options': [
            {
                'title': 'menu_documentos',
                'action': '/documentos',
                'perform': 'Documentos:index'
            },
            {
                'title': 'menu_documentos_cursos',
                'action': '/documentos/cursos',
                'perform': 'DocumentosCursos:index'
            },
            {
                'title': 'menu_documentos_alumnos',
                'action': '/documentos/alumnos',
                'perform': 'DocumentosAlumnos:index'
            }
        ]
    },
    {
        'id': 'TasksDropdown',
        'title': 'menu_tareas',
        'icon': 'assignment',
        'perform': 'Tareas:menu',
        'options': [
            {
                'title': 'menu_tareas',
                'action': '/tareas',
                'perform': 'Tareas:index'
            },
            {
                'title': 'menu_asignaciones',
                'action': '/tareas/alumnos',
                'perform': 'TareasAlumnos:index'
            }
        ]
    },
    {
        'id': 'BooksDropdown',
        'title': 'menu_biblioteca',
        'icon': 'library_books',
        'perform': 'Biblioteca:menu',
        'options': [
            {
                'title': 'menu_libros',
                'action': '/biblioteca/libros',
                'perform': 'BibliotecaLibros:index'
            },
            {
                'title': 'menu_secciones',
                'action': '/biblioteca/secciones',
                'perform': 'BibliotecaSecciones:index'
            },
            {
                'title': 'menu_socios',
                'action': '/biblioteca/socios',
                'perform': 'BibliotecaSocios:index'
            },
        ]
    },
    {
        'id': 'CustomersDropdown',
        'title': 'menu_customers',
        'icon': 'business',
        'perform': 'Customers:menu',
        'options': [
            {
                'title': 'menu_customers',
                'action': '/clientes',
                'perform': 'Customers:index'
            }
        ]
    },
    {
        'id': 'PlannerDropdown',
        'title': 'menu_planner',
        'icon': 'events',
        'perform': 'Planner:menu',
        'options': [
            {
                'title': 'menu_planner',
                'action': '/planner',
                'perform': 'Planner:index'
            },
            {
                'title': 'menu_calendario',
                'action': '/planner/calendario',
                'perform': 'Planner:calendar'
            },
            {
                'title': 'agenda_trainer', //El codigo de traduccion para el titulo que se visualiza.
                'action': '/agenda',
                'perform': 'Planner:calendar_trainer'
            },
        ]
    },
    {
        'id': 'PlanerTrainersDropdown',
        'title': 'Area Formador',
        'icon': 'events',
        'perform': 'PlannerTrainers:menu',
        'options': [                        
            {
                'title': 'agenda_trainer', //El codigo de traduccion para el titulo que se visualiza.
                'action': '/planner/agenda',
                'perform': 'Planner:calendar_trainer'
            },
            {
                'title': 'Diponibilidad', //El codigo de traduccion para el titulo que se visualiza.
                'action': '/planner/disponibilidad',
                'perform': 'Planner:calendar_trainer'
            },
        ]
    },
    {
        'id': 'FormadoresDropdown',
        'title': 'menu_formadores',
        'icon': 'group',
        'perform': 'Planner:menu',
        'options': [
            {
                'title': 'menu_formadores',
                'action': '/formadores-homologados',
                'perform': 'FormadoresHomologados:index'
            },
        ]
    },
    {
        'id': 'AdminDropdown',
        'title': 'menu_administracion',
        'icon': 'settings',
        'perform': 'Administracion:menu',
        'options': [
            {
                'title': 'menu_usuarios',
                'action': '/usuarios',
                'perform': 'Users:index'
            },
            // {
            //     'title': 'menu_permisos',
            //     'action': '/permisos',
            //     'perform': 'RolesPermissions:index'
            // },
            // {
            //     'title': 'menu_demarcaciones',
            //     'action': '/demarcaciones',
            //     'perform': 'Demarcaciones:index'
            // },
        ]
    }

];

const MenuBar = () => {

    const { t } = useTranslation();

    useEffect(() => {
        // Inicializar dropdowns
        const elems = document.querySelectorAll('.dropdown-menu');
        M.Dropdown.init(elems, {
            alignment: "right",
            constrainWidth: false,
            inDuration: 300,
            outDuration: 225,
            hover: false,
            coverTrigger: false,
            closeOnClick: true,
        });
    }, []);

    const renderSubMenu = (id, submenu) => {
        return (
            <ul className="dropdown-content dropdown-horizontal-list" id={id}>
                {submenu.map((item, index) => {
                    return (
                        <Can perform={item.perform} key={`can-${index}`}
                            yes={() => (
                                <li data-menu="" key={index}>
                                    <NavLink exact activeClassName="active" to={item.action}>
                                        <span data-i18n="Modern">
                                            {t(item.title)}
                                        </span>
                                    </NavLink>
                                </li>
                            )}
                        />
                    )
                })}
            </ul>
        );
    }

    return (
        <nav className="white hide-on-med-and-down" id="horizontal-nav">
            <div className="nav-wrapper">
                <ul className="left hide-on-med-and-down ps" id="ul-horizontal-nav" data-menu="menu-navigation">

                    {MENU_DEFINITION.map((menu, index) => {
                        return (
                            <Can perform={menu.perform} key={`can-${index}`}
                                yes={() => (
                                    <li key={index}>
                                        <a className="dropdown-menu" href="#!" data-target={menu.id}>
                                            <i className="material-icons">{menu.icon}</i>
                                            <span>
                                                <span className="dropdown-title" data-i18n="Dashboard">
                                                    {t(menu.title)}
                                                </span>
                                                <i className="material-icons right">keyboard_arrow_down</i>
                                            </span>
                                        </a>
                                        {renderSubMenu(menu.id, menu.options)}
                                    </li>
                                )}
                            />
                        )
                    })}

                </ul>
            </div>
        </nav>
    );

};

export default MenuBar;