import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { checkRBAC } from './Can';
import rbac_rules from './RBACRules';

import './DataTables.css';

import Header from '../components/UI/Header';
import Footer from '../components/UI/Footer';

import ScreensHomeWelcome from '../screens/Home/Welcome';

import ScreensAreaList from '../screens/Area/List';
import ScreensAreaForm from '../screens/Area/Form';

import ScreensBookList from '../screens/Book/List';
import ScreensBookForm from '../screens/Book/Form';
import ScreensBookSetionList from '../screens/Book/Section/List';
import ScreensBookSetionForm from '../screens/Book/Section/Form';
import ScreensBookMemberList from '../screens/Book/Member/List';
import ScreensBookMemberForm from '../screens/Book/Member/Form';

import ScreensStudentList from '../screens/Student/List';
import ScreensStudentForm from '../screens/Student/Form';
import ScreensStudentDegrees from '../screens/Student/Degrees';
import ScreensStudentView from '../screens/Student/View';
import ScreensStudentLoad from '../screens/Student/Load';

import ScreensCourseList from '../screens/Course/List';
import ScreensCourseForm from '../screens/Course/Form';
import ScreensCourseView from '../screens/Course/View';
import ScreensCourseOnlineLoad from '../screens/Course/Online/Load';
import ScreensCourseVideoLoad from '../screens/Course/Video/Load';
import ScreensCourseFaceToFaceLoad from '../screens/Course/FaceToFace/Load';

import ScreensTaskList from '../screens/Task/List';
import ScreensTaskForm from '../screens/Task/Form';
import ScreensTaskStudentList from '../screens/Task/Student/List';
import ScreensTaskStudentForm from '../screens/Task/Student/Form';
import ScreensTaskStudentDocuments from '../screens/Task/Student/Documents';

import ScreensDocumentList from '../screens/Document/List';
import ScreensDocumentForm from '../screens/Document/Form';

import ScreensDocumentCourseList from '../screens/Document/Course/List';
import ScreensDocumentCourseForm from '../screens/Document/Course/Form';

import ScreensDocumentStudentList from '../screens/Document/Student/List';
import ScreensDocumentStudentForm from '../screens/Document/Student/Form';

import ScreensDiplomaList from '../screens/Diploma/List';
import ScreensDiplomaForm from '../screens/Diploma/Form';

import ScreensStudentDiplomaList from '../screens/Diploma/Student/List';

import ScreensUserProfile from '../screens/User/Profile';
import ScreensUserList from '../screens/User/List';
import ScreensUserForm from '../screens/User/Form';

import ScreensCustomerList from '../screens/Customer/List';
import ScreensCustomerForm from '../screens/Customer/Form';
import ScreensCustomerView from '../screens/Customer/View';


import ScreensOrganizerList from '../screens/Organizer/List';
import ScreensOrganizerForm from '../screens/Organizer/Form';

import ScreensTrainerList from '../screens/Trainer/List';
import ScreensTrainerForm from '../screens/Trainer/Form';

import ScreensDegreeStudentList from '../screens/Degree/Student/List';
import ScreensDegreeStudentForm from '../screens/Degree/Student/Form';

import ScreensStudentCourseList from '../screens/Student/Course/List';
import ScreensStudentCourseForm from '../screens/Student/Course/Form';

import ScreensCourseStudentsList from '../screens/Course/Students';

import ScreensStudentCourseDone from '../screens/Student/Course/Done';

import ScreensStudentAreaCourses from '../screens/StudentArea/Courses';
import ScreensStudentAreaDocuments from '../screens/StudentArea/Documents';
import ScreensStudentAreaTasks from '../screens/StudentArea/Tasks';
import ScreensStudentAreaDiplomas from '../screens/StudentArea/Diplomas';

import ScreensPipeDriveList from '../screens/PipeDrive/List';
// import ScreensCustomerForm from '../screens/Customer/Form';
// import ScreensCustomerView from '../screens/Customer/View';

import ScreensPlannerList from '../screens/Planner/List';
import ScreensPlannerCalendar from '../screens/Planner/Calendar';
import ScreensPlannerCalendarTrainer from '../screens/Planner/CalendarTrainer';
import ScreensPlannerForm from '../screens/Planner/Form';
import ScreensPlannerFormGroup from '../screens/Planner/FormGroup';
import ScreensPlannerFormPrevent from '../screens/Planner/FormPrevent';

const DashboardRoutes = () => {

    const ROUTES = [
        { path: '/area-alumno/cursos', component: ScreensStudentAreaCourses, perform: 'AreaAlumno:AlumnosCursos:index' },
        { path: '/area-alumno/documentos', component: ScreensStudentAreaDocuments, perform: 'AreaAlumno:Documentos:index' },
        { path: '/area-alumno/tareas', component: ScreensStudentAreaTasks, perform: 'AreaAlumno:TareasAlumnos:index' },
        { path: '/area-alumno/diplomas', component: ScreensStudentAreaDiplomas, perform: 'AreaAlumno:AlumnosDiplomas:index' },

        { path: '/', component: ScreensHomeWelcome, perform: 'Home'},
        
        { path: '/usuarios/perfil', component: ScreensUserProfile, perform: 'Users:profile' },
        { path: '/usuarios', component: ScreensUserList, perform: 'Users:index' },
        { path: '/usuarios/formulario', component: ScreensUserForm, perform: 'Users:add' },

        { path: '/biblioteca/libros', component: ScreensBookList, perform: 'BibliotecaLibros:index' },
        { path: '/biblioteca/libros/formulario', component: ScreensBookForm, perform: 'BibliotecaLibros:add' },
        { path: '/biblioteca/secciones', component: ScreensBookSetionList, perform: 'BibliotecaSecciones:index' },
        { path: '/biblioteca/secciones/formulario', component: ScreensBookSetionForm, perform: 'BibliotecaSecciones:add' },
        { path: '/biblioteca/socios', component: ScreensBookMemberList, perform: 'BibliotecaSocios:index' },
        { path: '/biblioteca/socios/formulario', component: ScreensBookMemberForm, perform: 'BibliotecaSocios:add' },

        { path: '/demarcaciones', component: ScreensAreaList, perform: 'Demarcaciones:index' },
        { path: '/demarcaciones/formulario', component: ScreensAreaForm, perform: 'Demarcaciones:add' },
        
        { path: '/alumnos', component: ScreensStudentList, perform: 'Alumnos:index' },
        { path: '/alumnos/formulario', component: ScreensStudentForm, perform: 'Alumnos:add' },
        { path: '/alumnos/detalle', component: ScreensStudentView, perform: 'Alumnos:view' },
        { path: '/alumnos/cursos-realizados', component: ScreensStudentCourseDone, perform: 'AlumnosCursos:cursosRealizados' },
        { path: '/alumnos/titulaciones', component: ScreensStudentDegrees, perform: 'AlumnosTitulaciones:index' },
        { path: '/alumnos/cargar', component: ScreensStudentLoad, perform: 'Alumnos:load' },

        { path: '/cursos', component: ScreensCourseList, perform: 'Cursos:index' },
        { path: '/cursos/formulario', component: ScreensCourseForm, perform: 'Cursos:add' },
        { path: '/cursos/detalle', component: ScreensCourseView, perform: 'Cursos:view' },
        { path: '/cursos/online/carga', component: ScreensCourseOnlineLoad, perform: 'Cursos:cargarCursosOnline' },
        { path: '/cursos/carga-inscripciones-presencial', component: ScreensCourseFaceToFaceLoad, perform: 'Cursos:cargarInscripcionesPresencial' },
        { path: '/cursos/carga-inscripciones-video', component: ScreensCourseVideoLoad, perform: 'Cursos:cargarInscripcionesVideo' },
        { path: '/cursos/alumnos-inscritos', component: ScreensCourseStudentsList, perform: 'Cursos:verInscripciones' },

        { path: '/cursos/inscripciones', component: ScreensStudentCourseList, perform: 'AlumnosCursos:index' },
        { path: '/cursos/inscripciones/formulario', component: ScreensStudentCourseForm, perform: 'AlumnosCursos:add' },
        
        { path: '/clientes', component: ScreensCustomerList, perform: 'Customers:index' },
        { path: '/clientes/formulario', component: ScreensCustomerForm, perform: 'Customers:add' },
        { path: '/clientes/detalle', component: ScreensCustomerView, perform: 'Customers:view' },

        { path: '/tareas', component: ScreensTaskList, perform: 'Tareas:index' },
        { path: '/tareas/formulario', component: ScreensTaskForm, perform: 'Tareas:add' },

        { path: '/tareas/alumnos', component: ScreensTaskStudentList, perform: 'TareasAlumnos:index' },
        { path: '/tareas/alumnos/formulario', component: ScreensTaskStudentForm, perform: 'TareasAlumnos:add' },
        { path: '/tareas/alumnos/archivos', component: ScreensTaskStudentDocuments, perform: 'TareasAlumnosDocumentos:index' },

        { path: '/documentos', component: ScreensDocumentList, perform: 'Documentos:index' },
        { path: '/documentos/formulario', component: ScreensDocumentForm, perform: 'Documentos:add' },
        { path: '/documentos/cursos', component: ScreensDocumentCourseList, perform: 'DocumentosCursos:index' },
        { path: '/documentos/cursos/formulario', component: ScreensDocumentCourseForm, perform: 'DocumentosCursos:add' },
        { path: '/documentos/alumnos', component: ScreensDocumentStudentList, perform: 'DocumentosAlumnos:index' },
        { path: '/documentos/alumnos/formulario', component: ScreensDocumentStudentForm, perform: 'DocumentosAlumnos:add' },

        { path: '/diplomas', component: ScreensDiplomaList, perform: 'Diplomas:index' },
        { path: '/diplomas/formulario', component: ScreensDiplomaForm, perform: 'Diplomas:add' },
        
        { path: '/diplomas/alumnos', component: ScreensStudentDiplomaList, perform: 'AlumnosDiplomas:index' },
        
        { path: '/organizadores', component: ScreensOrganizerList, perform: 'Organizadores:index' },
        { path: '/organizadores/formulario', component: ScreensOrganizerForm, perform: 'Organizadores:add' },

        { path: '/formadores-homologados', component: ScreensTrainerList, perform: 'FormadoresHomologados:index' },
        { path: '/formadores-homologados/formulario', component: ScreensTrainerForm, perform: 'FormadoresHomologados:add' },

        { path: '/alumnos/buscador-titulaciones', component: ScreensDegreeStudentList, perform: 'AlumnosTitulaciones:index' },
        { path: '/alumnos/titulaciones/formulario', component: ScreensDegreeStudentForm, perform: 'AlumnosTitulaciones:edit' },
    
        { path: '/pipedrive', component: ScreensPipeDriveList, perform: 'PipeDrive:index' },
        { path: '/planner', component: ScreensPlannerList, perform: 'Planner:index' },
        { path: '/planner/calendario', component: ScreensPlannerCalendar, perform: 'Planner:calendar' },
        { path: '/planner/agenda', component: ScreensPlannerCalendarTrainer, perform: 'Planner:calendar_trainer' },
        { path: '/planner/formulario', component: ScreensPlannerForm, perform: 'Planner:add' },
        { path: '/planner/formgroup', component: ScreensPlannerFormGroup, perform: 'Planner:add'},
        { path: '/planner/formprevent', component: ScreensPlannerFormPrevent, perform: 'Planner:add'},
    
    ];

    const BODY_CLASS_LIST = () => [
        'horizontal-layout', 
        'page-header-light', 
        'horizontal-menu',
        'preload-transitions',
        '2-columns'
    ];
    const BODY_ATTRIBUTES = () => ({
        'data-open': 'click',
        'data-menu': 'horizontal-menu',
        'data-col': '2-columns'
    });

    const { user } = useSelector(state => state.auth);

    useEffect(() => {

        const bodyClassList = BODY_CLASS_LIST();
        const bodyAttributes = BODY_ATTRIBUTES();

        // Set body properties.
        document.body.classList.add(...bodyClassList);
        for (const key of Object.keys(bodyAttributes)) {
            document.body.setAttribute(key, bodyAttributes[key]);
        }

        return () => {
            // Unset body properties.
            document.body.classList.remove(...bodyClassList);
            for (const key of Object.keys(bodyAttributes)) {
                document.body.removeAttribute(key, bodyAttributes[key]);
            }
        };

    }, []);

    const renderRoutes = () => {
        return (
            <Switch>
                { ROUTES.map((route) => (
                    <Route exact path={ route.path } 
                        component={
                            checkRBAC(user, rbac_rules, route.perform, {})
                            ? route.component
                            : ScreensHomeWelcome
                        } 
                        key={ route.path }
                    />           
                )) }
                {/* 404 */}
                <Redirect to="/" />
            </Switch>
        );
    }

    return (
        <>
            <Header />
            <div id="main">
                <div className="row">
                    { renderRoutes() }
                </div>
            </div>
            <Footer />
        </>
    );
};

export default DashboardRoutes;
